import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import { PageProps, graphql } from "gatsby"
import useScrollToTop from "~/hooks/useScrollToTop"
import { I18nContext } from "~/components/I18n"

export const query = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    wpPage(id: {eq: $id}) {
      title
      content
    }
  }
`

export default ({
  data: {
    wpPage: {
      title,
      content,
    },
    site: {
      siteMetadata: {
        title: siteTitle,
        author,
        siteUrl
      },
    },
  },
}: PageProps<{
  site: {
    siteMetadata: {
      title: string
      description: string
      image: string
      author: string
      siteUrl: string
      apiUrl: string
    }
  }
  wpPage: {
    title: string
    content: string
  }
}>) => {
  useScrollToTop();

  const { locale } = useContext(I18nContext)

  return (
    <>
      <Helmet
        defer={false}
        htmlAttributes={{
          lang: locale,
          dir: "ltr",
          prefix: "og: http://ogp.me/ns#",
        }}
      >
        <title>{title} | {siteTitle}</title>
        <link rel="author" href="/humans.txt" />

        <meta name="description" content={''} />

        <meta property="og:title" content={title} />
        <meta property="og:locale" content={locale} />
        <meta property="og:description" content={''} />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={author} />
        <meta name="twitter:creator" content={author} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={''} />


      </Helmet>

      <header>
        <h1>{title}</h1>
      </header>

      <article
        className="is-post prose has-margin-bottom"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </>
  )
}
